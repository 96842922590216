import React from "react"
import { Link } from "gatsby"
import { BsArrowRight } from "react-icons/bs"
import "./index.scss"

function UseCaseCardItem({ card }) {
  const { title, subTitle, cardImage } = card.frontmatter
  const slug = card.slug
  return (
    <div className="UseCaseCardItem p-3 p-sm-5" data-testid="UseCaseCardItem">
      <Link to={`/use-cases/${slug}`} className="link">
        <div className="titleContainer  row align-items-center">
          <div className="image col-8 ">
            <img
              src={cardImage}
              alt={`maxapps use cases: ${title}`}
              title={`maxapps use cases: ${title}`}
              className="imageStyle img-fluid"
              data-testid="image"
            />
          </div>

          <div className=" title col-4 justify-content-end ">
            <h2 className="titleStyle">{title}</h2>
          </div>
        </div>

        <div className="pargContainer pt-3">
          <div className="col-12 col-md-9">
            <p
              className="description"
              dangerouslySetInnerHTML={{ __html: subTitle }}
            ></p>
          </div>
        </div>
        <div className="btnContainer mt-2 py-2 ">
          <div className="">
            <button type="button" className="btn">
              Learn More
              <BsArrowRight size={20} />
            </button>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default UseCaseCardItem
