import React from "react"
import { Helmet } from "react-helmet"
import Seo from "../../seo"
import UseCaseCards from "./use-case-card"
const UseCase = () => {
  return (
    <div>
      <Seo
        title="Companies that trust MAXAPPS IBM Maximo"
        description="MAXAPPS has entered into several partnerships with the professional world. These partnerships make a real contribution to the development of our business"
      />
      <Helmet prepend>
        <meta property="og:title" content="MAXapps Use Cases" />
        <meta
          property="og:description"
          content="Many organizations of all shapes and sizes trust MAXapps. Discover a few of their projects, use cases and results."
        />
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/use-cases.jpg"
        />
        <meta
          property="og:url"
          content="https://maxapps-maximo.com/use-cases"
        />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
        <meta name="twitter:title" content="MAXapps Use Cases" />
        <meta
          name="twitter:description"
          content="Many organizations of all shapes and sizes trust MAXapps. Discover a few of their projects, use cases and results."
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/use-cases.jpg"
        />
      </Helmet>
      <UseCaseCards />
    </div>
  )
}

export default UseCase
