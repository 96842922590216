import React from "react"
import LayoutLight from "../../components/layout/light/layout"
import UseCase from "../../components/use-cases/use-case"
const UseCasesPage = () => {
  return (
    <LayoutLight>
      <UseCase />
    </LayoutLight>
  )
}

export default UseCasesPage
