import React from "react"
import UseCaseCardItem from "./UseCaseCardItem"
import "./index.scss"
import { useStaticQuery, graphql } from "gatsby"
import { BsArrowRight } from "react-icons/bs"
import YellowButton from "../../../home/buttons/yellow-button"

const UseCaseCards = ({ _card }) => {
  let cardInfo = []

  try {
    const data = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(use-cases)/" } }) {
          nodes {
            frontmatter {
              title
              subTitle
              cardImage
            }
            slug
          }
        }
      }
    `)
    cardInfo = data.allMdx.nodes
  } catch (error) {
    cardInfo = _card
  }
  return (
    <div
      id="UseCaseCards"
      className=" UseCaseCards "
      data-testid="UseCaseCards"
    >
      <div className="sect  mb-5">
        <h1 className="centeredText ">Use Cases</h1>
        <p className="centeredText">
          Many organizations of all shapes and sizes trust MAXapps. Discover a
          few of their projects, use cases and results.
        </p>
      </div>
      <div className="sect row py-2">
        {cardInfo?.map((card, index) => {
          return (
            card.frontmatter.subTitle && (
              <div
                className="col-12 col-lg-6 p-0"
                key={index}
                data-testid={index}
              >
                <UseCaseCardItem card={card} />
              </div>
            )
          )
        })}
      </div>
      <div className="row justify-content-center  py-5 my-5">
        <span className="centeredTextBottom ">
          Ready To Build
          <br />
          Your First MAXapps Mobile Solution
          {"?"}
        </span>
        <div className="btn-usecase-bottom mt-3 py-2">
          <YellowButton
            data={"Get started"}
            size={"180px"}
            children={
              <BsArrowRight size={20} className="yellow-btn-arrow ms-2" />
            }
            link="/docs/Tutorials/"
          />
        </div>
      </div>
    </div>
  )
}

export default UseCaseCards
